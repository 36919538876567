<template>
  <div class="404">
    <section class="section parallax-container text-center parallax-header context-dark margin-404">
      <div class="parallax-content">
        <div class="parallax-header__inner2">
          <div class="parallax-header__content">
            <div class="container">
              <div class="row row-fix justify-content-sm-center">
                <div class="col-md-10 col-xl-8" style="font-weight: 900; margin-top: 50px">
                  <h2>{{ $t('lang.404.title') }}</h2>
                  <p>
                    {{ $t('lang.404.description') }}
                  </p>
                  <a class="button button-black btn-back" href="/">{{ $t('lang.404.btn') }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section context-dark">
      <div class="container-fluid" style="padding-right: 0px; padding-left: 0px">
        <div class="row row-30 align-items-center">
          <div class="col-xl-12">
            <!-- RD Video-->
            <div class="rd-video play-on-scroll rd-video-player" data-rd-video-path="video/video-404">
              <div class="rd-video-wrap">
                <div class="rd-video-preloader"></div>
                <video></video>
                <div class="rd-video-controls">
                  <!-- Play\Pause button--><a class="rd-video-play-pause mdi mdi-play icon icon-lg" href="#"></a>
                  <!-- Progress bar-->
                  <div class="rd-video-progress-bar">
                    <div class="current" style="width: 0%"></div>
                  </div>
                  <div class="rd-video-time">
                    <!-- currentTime--><span class="rd-video-current-time">00:38</span>
                    -
                    <!-- Track duration--><span class="rd-video-duration">02:49</span>
                  </div>
                  <div class="rd-video-volume-wrap">
                    <!-- Volume button--><a class="rd-video-volume mdi mdi-volume-high icon icon-sm" href="#"></a>
                    <div class="rd-video-volume-bar-wrap">
                      <!-- Volume bar-->
                      <div class="rd-video-volume-bar rd-video-volume-bar-vertical"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
export default Vue.extend({
  name: '404',
  data: () => ({})
});
</script>

<style>
.rd-video-wrap {
  padding-bottom: 42vw !important;
}

@media (max-width: 767px) {
  .rd-video-wrap {
    padding-bottom: 56% !important;
  }

  #about {
    margin-top: -100px;
  }
}
</style>
